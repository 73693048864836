<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <g id="Icona_rata" transform="translate(-716 -1014)">
      <rect id="Icon_fin_spacer" width="48" height="48" transform="translate(716 1014)" fill="none"/>
      <g id="Icona_cofidis_leggera" transform="translate(715.92 1014.1)">
        <path id="Path_8127" data-name="Path 8127" d="M2.08,47.11h12.5V6.61H2.08Zm10.5-38.5v36.5H4.08V8.61Z" fill="#252525"/>
        <path id="Path_8128" data-name="Path 8128" d="M17.4,47.11H29.9V16.51H17.4Zm10.5-28.6V45.12H19.4V18.51Z" fill="#252525"/>
        <path id="Path_8129" data-name="Path 8129" d="M33.4,24.27V47.11H45.9V24.27Zm2,20.84V26.27h8.5V45.11Z" fill="#252525"/>
        <path id="Path_8130" data-name="Path 8130" d="M18.47,7.45a.375.375,0,0,1-.1-.01.669.669,0,0,0-.43.07.616.616,0,0,0-.23.37c-.01.03-.02.07-.03.1l-.26.61-.02.37h1.77a5.813,5.813,0,0,0,.57,1.34,6.244,6.244,0,0,0,4.53,3.13c.13.02.26.04.39.05l.21.02h.76l.37-.05a6.286,6.286,0,0,0,3.16-1.34.472.472,0,0,0,.17-.35v-1.2a.41.41,0,0,0-.01-.11l-.07-.51-.35.37a4.771,4.771,0,0,1-5.25,1.4,4.579,4.579,0,0,1-2.88-2.76h6.29a.34.34,0,0,0,.3-.19c.11-.25.21-.51.33-.78l.22-.54H20.45v-.5h7.12a.277.277,0,0,1,.09.01.584.584,0,0,0,.42-.07.616.616,0,0,0,.23-.37.689.689,0,0,1,.03-.08l.42-1H20.78a4.551,4.551,0,0,1,2.7-2.68,4.777,4.777,0,0,1,5.46,1.36l.26.27.22-.52c.11-.26.22-.52.32-.79a.381.381,0,0,0-.1-.38A6.254,6.254,0,0,0,26.36,1c-.16-.03-.33-.05-.49-.07L25.64.9H24.8l-.25.03c-.19.03-.38.05-.57.09a6.142,6.142,0,0,0-3.34,1.84,6.243,6.243,0,0,0-1.48,2.57h-.97a.38.38,0,0,0-.4.26c-.09.21-.18.42-.27.64l-.1.24-.02.37h1.52v.51Z" fill="#252525"/>
      </g>
    </g>
  </svg>
</template>

<style scoped></style>
